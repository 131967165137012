.step-progress-bar {
  width: 100%;
  height: 60px;
  position: relative;
  margin-bottom: 20px;
}

.real-progress-bar {
  width: calc(100% - 15px);
  height: 10px;            
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-right: 5px;
  margin-left: 5px;
}

.progress-bar-steps {
  width: 100%;
  height: 100%;            
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progress-bar-step {
  position: relative;
  background-color: var(--bs-secondary);
  color: white;
  font-weight: 700;
  font-size: 1.1rem;
  border-radius: 50%;
  height: 33px;
  width: 33px;
  z-index: 100;
  text-align: center;
  line-height: 33px;
  cursor: pointer;
}

.progress-bar-step-text {
  position: absolute;
  bottom: -50%;
  left: 50%;
  transform: translate(-50%, 50%);
  font-size: 0.9rem;
  font-weight: 500;
  color: black;
  text-align: center;
  white-space: nowrap;
}

.progress-bar-step .first {
  left: 0%;
  transform: translate(0%, 50%);
}

.progress-bar-step .last  {
  left: 100%;
  transform: translate(-100%, 50%);
}

.progress-bar-step.done {
  background-color: var(--bs-success);
}
