.user-card {
  background-color: white;
  color: var(--bs-secondary);
  transition-property: "background-color, transform";
  transition-duration: 0.3s;
  transform: scale(1);
}

.user-card.active {
  cursor: pointer;
  color: var(--bs-grey);
}

.user-card.active:hover {
  background-color: var(--bs-light);
  color: var(--bs-success);
  transform: scale(1.03);
}