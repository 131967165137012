body {
  margin: 0;
  font-family: 'Poppins', 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

.link {
  color: inherit;
  text-decoration: inherit;
}