.form-page {
  position: relative;
}

.form-field {
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 0px !important;
}

.form-field.drawable {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.05);
}

.form-field.other-selected {
  box-shadow: 0 0 0px 5px rgb(40, 167, 69, 0.5), 0 0 0px 1px rgb(40, 167, 69);
}

.form-field.required {
  box-shadow: 0 0 0px 5px rgb(250, 105, 18, 0.5), 0 0 0px 1px rgb(250, 105, 18);
}

.warning-info {
  cursor: pointer;
}
